<template>
  <div class="route-progress">
    <off-path v-if="offPath"></off-path>

    <default-image v-else-if="error || !onTrip" :alt="defaultAltMsg"></default-image>

    <div v-else-if="progressInfo.stopList && !offPath && progressInfo.departureDestinationsDisplayName != null" class="content-wrapper">
      <route-info :routeName="progressInfo.departureDestinationsDisplayName" :routeVia="progressInfo.departureDestinationsViaName" :line="progressInfo.line"></route-info>

      <div v-if="!connectionOk" class="overlay"></div>
      <only-boarding v-if="progressInfo.nextAlightingAfterOnlyBoarding" :alighting-stop="progressInfo.nextAlightingAfterOnlyBoarding" />
      <stop-list
        v-else
        :stopList="progressInfo.stopList"
        :isAmplifier="progressInfo.announceSound"
        :activeDate="progressInfo.activeDate"
        :createdDate="progressInfo.createdTime"
        :showStopSignal="stopSignalActive"
        :displayRegulationStopInfo="progressInfo.displayRegulationStopInfo"
        :notificationHubInfoMessages="notificationHubInfoMessages"
        :forecasts="forecasts"
      >
      </stop-list>
      <notification v-show="!connectionOk" icon-path="/img/signal-strength-none-yellow.png" msg="Venter på dekning"></notification>
    </div>

    <default-image v-else :alt="defaultAltMsg"></default-image>
  </div>
</template>

<script>
import StopList from '@/components/StopList'
import RouteInfo from '@/components/RouteInfo'
import { delayWithPromise, secondsFromNow } from '@/utils/utilMisc'
import DefaultImage from '@/components/DefaultImage'
import OffPath from '@/components/OffPath'
import networkConnectivityListener from '@/mixins/networkConnectivityListener'
import OnlyBoarding from '@/components/OnlyBoarding'
import { logger } from '@/utils/logger'
import { getVehicleIdByMac, getMacByVehicleId, isVehicleIdValid } from '@/shared/common'
import Notification from '@/components/Notification'
import NotificationHubInfoMessageListener from '@/mixins/notificationHubInfoMessageListener'

export default {
  name: 'VehicleRouteProgress',
  components: { Notification, OnlyBoarding, RouteInfo, StopList, DefaultImage, OffPath },
  mixins: [networkConnectivityListener, NotificationHubInfoMessageListener],
  props: {
    mac: {
      type: String,
      default: null,
    },
    vehicleId: {
      type: Number,
      default: null,
    },
    testing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vehicleId_: null,
      defaultAltMsg: null,
      offPath: false,
      trackingInterval: null,
      error: false,
      onTrip: false,
      connectionOk: true,
      onlineDelay: null,
      onlineError: null,
      progressInfo: {},
      forecasts: {},
      stopSignalActive: false,
    }
  },
  computed: {
    tripId: {
      get() {
        return parseInt(sessionStorage.getItem('tripId'), 10)
      },
      set(value) {
        sessionStorage.setItem('tripId', value)
      },
    },
  },
  watch: {
    isOnline: {
      async handler(val) {
        if (val === false) {
          this.onlineDelay = setTimeout(() => {
            this.connectionOk = false
          }, 15000)

          this.onlineError = setTimeout(() => {
            this.error = true
            this.defaultAltMsg = 'Lost connection'
          }, 90000)
        } else if (val === true) {
          clearTimeout(this.onlineError)
          clearTimeout(this.onlineDelay)
          this.connectionOk = true
          // When we get back online and prev message is old, then try to fetch fresh data
          delayWithPromise(1000).then(() => {
            if (!this.progressInfo.createdTime || secondsFromNow(this.progressInfo.createdTime) > 2) {
              this.loadCurrentData()
            }
          })
        }
      },
    },
  },
  async created() {
    if (this.mac == null && isVehicleIdValid(this.vehicleId)) {
      this.mac = await getMacByVehicleId(this.vehicleId)
      localStorage.setItem('vehicleId', this.vehicleId)
    }

    await this.$signalrConnection.start(this.mac, this.testing)
    logger.info('Subscribing to SignalR Connection NextStopsList event', { testing: this.testing })

    this.registerSignalrHandlers()
    document.addEventListener('signalrconnected', this.registerSignalrHandlers)

    await this.loadCurrentData()
  },
  beforeDestroy() {
    this.forecasts = {}

    this.$signalrConnection.off('StopSignalChanged')
    logger.info('Unsubscribing from SignalR Connection StopSignalChanged event', { testing: this.testing })

    this.$signalrConnection.off('NextStopsList')
    logger.info('Unsubscribing from SignalR Connection NextStopsList event', { testing: this.testing })

    this.$signalrConnection.off('Forecast')
    logger.info('Unsubscribing from SignalR Connection Forecast event', { testing: this.testing })

    clearInterval(this.trackingInterval)
    clearTimeout(this.onlineDelay)
    clearTimeout(this.onlineError)
  },
  methods: {
    registerSignalrHandlers() {
      // Listen to SignalR events to updated the list of stops
      this.$signalrConnection.on('NextStopsList', async msg => {
        const stopPlaceId = (msg.stopList && (msg.stopList.length > 0)) ? msg.stopList[0].stopPlaceId : '';
        const sequenceOrder = (msg.stopList && (msg.stopList.length > 0)) ? msg.stopList[0].sequenceOrder : 0;
        const payload = {
          Mac: sessionStorage.getItem('mac'),
          VehicleId: msg.vehicleId,
          TripId: msg.tripId || 0,
          ActiveDate: msg.activeDate,
          StopPlaceId: stopPlaceId,
          SequenceOrder: sequenceOrder,
          IsKioskClient: true,
          Payload: JSON.stringify({ ConnectionId: this.$signalrConnection.connection.connectionId, Testing: this.testing }),
        }
        await this.setup(msg, false)
        await this.signalRCallback('stop-list-callback', payload)
      })

      // Listen to SignalR events to updated stop signal status
      this.$signalrConnection.on('StopSignalChanged', async msg => {
        const payload = {
          Mac: sessionStorage.getItem('mac'),
          VehicleId: msg.vehicleId,
          StopId: msg.stopId,
          StopSequence: msg.stopSequence ?? 0,
          Active: msg.active,
          TripId: msg.tripId ?? 0,
          IsKioskClient: true,
          Payload: JSON.stringify({ ConnectionId: this.$signalrConnection.connection.connectionId, Testing: this.testing }),
        }
        this.stopSignalChanged(msg)
        await this.signalRCallback('stop-signal-changed-callback', payload)
      })

      this.$signalrConnection.on('Forecast', items => {
        logger.event('Forecast event received in client', { connectionId: this.$signalrConnection.connection.connectionId, testing: this.testing })
        const validItems = items.filter(x => x.targetTripId == this.tripId)
        for (const v of validItems) {
          this.forecasts[v.targetStopSequenceOrder] = v
        }
        console.log(validItems)
      })
    },
    async signalRCallback(route, data) {
      try {
        await this.$http.post(`/api/${route}`, data)
      } catch (error) {
        logger.error('SignalR callback failed:', error.message)
      }
    },
    async setup(vm, initialLoad) {
      this.error = false

      this.progressInfo = vm

      this.vehicleId_ = vm.vehicleId
      this.onTrip = (vm.stopList && (vm.stopList.length > 0))

      if (initialLoad || this.tripId !== vm.tripId) {
        // fetch not hub info messages the first time and whenever the trip changes, otherwise let signalr keep the messages up to date.
        // Can have long duration, which we don't want to await
        this.updateNotificationHubInfoMessages(this.vehicleId_).catch((error) => {
          logger.error('Failed to update notificationHubInfoMessages', error.message)
        });
      }

      this.tripId = vm.tripId

      await this.loadCurrentForecastData(vm)
    },
    stopSignalChanged({ active }) {
      this.stopSignalActive = active
    },
    stopSignalConnect(vm) {
      // Set the stop signal according to the fetched vehicleState
      if (vm.stopSignal) {
        this.stopSignalActive = vm.stopSignal.active
      } else {
        this.stopSignalActive = false
      }
    },
    async loadCurrentData() {
      await this.assureVehicleIdSet()
      try {
        const response = await this.$http.get(`/api/stop/vehicle/${Number(this.vehicleId_)}/current`)
        if (response.data) {
          // When the SignalR connection is created, we check if stopSignal is updated
          this.stopSignalConnect(response.data)
          await this.setup(response.data, true)
        }
      } catch (error) {
        logger.error('Failed to eagerly load stop list', error.message)
      }
    },
    async assureVehicleIdSet() {
      this.vehicleId_ = isVehicleIdValid(this.vehicleId) ? this.vehicleId : (await getVehicleIdByMac(this.mac))
      if (!this.vehicleId_) {
        console.error("Routing to root: '/' as we were unable to fetch vehicleId by mac")
        await this.$router.push('/')
      }
    },
    async loadCurrentForecastData(vm) {
      console.log('Loading forecasting data from Wall-E')

      try {
        let forecasts = {}

        const response = await this.$http.get(
          `https://walle.kolumbus.no/api/forecasts/${vm.tripId}`,
          { headers: { 'Version': 'v1.0' } }
        )

        for (const x of response.data) {
          forecasts[x.sequence_order] = {
            targetTripId: x.trip_id,
            targetStopSequenceOrder: x.sequence_order,
            predictedDepartureTime: x.predicted_departure_time,
            predictedArrivalTime: x.predicted_arrival_time,
          }
        }

        this.forecasts = forecasts
      } catch (error) {
        this.forecasts = {}
        logger.error('Failed to load forecast data from Wall-E', error.message)
      }
    }
  },
}
</script>

<style scoped lang="scss">
.route-progress {
  font-family: PostGrotesk, Helvetica, Arial, sans-serif;
  height: 100%;
}

.content-wrapper {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 400;
}
</style>
